import React from 'react';
import './Presentacion.css';

function Presentacion() {
    return (
        <section className="Presentacion">
            <div className="overlay_s1"></div>
            <div className="info">
              <h2 className="info_titulo">Hola!! soy Kelly</h2>
              <h2 className="info_sub_titulo">Gracias por hacernos parte de tu celebración.</h2>
              <p className="info_description">
                Ponte en contacto con nosotros y empecemos a soñar.
              </p>
            </div>
        </section>
    );
}

export default Presentacion;
