import React from 'react';
import './App.css';
import Header from './Header';
import Presentacion from './Presentacion';
import CarruselImg from './CarruselImg';
import Productos from './Productos';
import Contactenos from './Contactenos';
import Footer from './Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <main className="App-body">
        <Presentacion />
        <CarruselImg />
        <Productos />
        <Contactenos />
        <Footer />
      </main>
    </div>
  );
}

export default App;
