import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <footer className="Footer">
            <div className="Footer_main">
                <div className="Footer_left">
                    <p>Copyright © 2024 gusticoscakes.com</p>
                </div>
                <div className="Footer_right">
                    <p>Powered by gusticoscakes.com</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
