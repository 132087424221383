import React from 'react';
import './Header.css';

function Header() {
  return (
    <header className="App-header">
      <div className="header-content">
        <div className="logo">
          <span>
              <img
                src='./img/logo_new.png'
                className="custom-logo"
                alt="gusticoscakes.com"
              />
          </span>
        </div>
        <div className="header-buttons">
          <button className="btn-instagram" onClick={() => window.open('https://www.instagram.com/gusticoscakes/', '_blank', 'noopener noreferrer')}>Instagram</button>
          <button className="btn-whatsapp" onClick={() => window.open('https://wa.me/+34603294260', '_blank', 'noopener noreferrer')}>WhatsApp</button>
        </div>
      </div>
    </header>
  );
}

export default Header;
